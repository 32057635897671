/**
 * @description Cities related to manual_* (b2b) flows
 */
export const MANUAL_CITIES = [
  { id: 'avellino', name: 'Avellino' },
  { id: 'bergamo', name: 'Bergamo' },
  { id: 'bologna', name: 'Bologna' },
  { id: 'brescia', name: 'Brescia' },
  { id: 'firenze', name: 'Firenze' },
  { id: 'foggia', name: 'Foggia' },
  { id: 'genova', name: 'Genova' },
  { id: 'lecce', name: 'Lecce' },
  { id: 'milano', name: 'Milano' },
  { id: 'monza', name: 'Monza' },
  { id: 'padova', name: 'Padova' },
  { id: 'roma', name: 'Roma' },
  { id: 'siena', name: 'Siena' },
  { id: 'torino', name: 'Torino' },
  { id: 'varese', name: 'Varese' },
  { id: 'palermo', name: 'Palermo' },
  { id: 'catania', name: 'Catania' },
  { id: 'siracusa', name: 'Siracusa' },
  { id: 'latina', name: 'Latina' },
  { id: 'ancona', name: 'Ancona' },
  { id: 'messina', name: 'Messina' },
  { id: 'agrigento', name: 'Agrigento' },
  { id: 'olbia', name: 'Olbia' },
  { id: 'napoli', name: 'Napoli' },
  { id: 'cosenza', name: 'Cosenza' },
  { id: 'calabria', name: 'Calabria' },
  { id: 'pisa', name: 'Pisa' },
  { id: 'vicenza', name: 'Vicenza' },
  { id: 'perugia', name: 'Perugia' },
  { id: 'bari', name: 'Bari' },
];
