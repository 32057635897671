import React, { useEffect, useRef, useState } from 'react';
import {
  AutocompleteInput,
  DateField,
  DateTimeInput,
  FormWithRedirect,
  Labeled,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextField,
  TextInput,
  useEditController,
  useNotify,
  useRefresh,
} from 'react-admin';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FormWithRedirectProps } from 'ra-core/esm/form/FormWithRedirect';
import {
  reservationData,
  reservationServiceData,
  SUPPORTED_CITIES,
} from '@ugo/data';
import { Mutator } from 'final-form';
import {
  Reservation,
  Reservation_Status_Enum,
  useFinishReservationMutation,
  useGetDistanceAndDurationQuery,
  useGetReservationById_Administrator_20220601Query,
  useResendStripeCheckoutSessionPaymentRequestMutation,
} from '@ugo/graphql-operations';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import {
  calculateFinalTravelDistanceAndDuration,
  calculateTransactionFees,
  sortObjectKeysAlphabetically,
  useQuoteCalculations,
} from '@ugo/quote-calculations';
import { useDates } from '../../hooks/useDates';
import { create } from 'react-modal-promise';
import { EmailWillBeSentOutAlert } from '../shared/EmailWillBeSentOutAlert';
import { AddMissingOverdueReservationDataModal } from '../shared/AddMissingOverdueReservationData';
import { LocationInput } from '../shared/LocationInput';
import { HasuraSessionVariables, Roles, ServicesEnum } from '@ugo/models';
import { Button } from 'ra-ui-materialui';
import { Computer } from '@material-ui/icons';
import { CalculateTransactionFeesInterface } from '../../../../../libs/quote-calculations/src/type/calculateTransactionFees.interface';
import { Alert } from '@material-ui/lab';
import { BlockingOverlay } from '../shared/BlockingOverlay';
import QuoteCalculator, { Cost, Travel } from '../../../utils/calculations';
import { useDataProvider } from 'ra-core';

export const ReservationEditForm = (
  props: Omit<FormWithRedirectProps, 'render'>
) => {
  const { save } = useEditController(props);
  const refresh = useRefresh();
  const [showBlockingOverlay, setShowBlockingOverlay] = useState(false);

  const dataProvider = useDataProvider();
  const { getDurationInHours, fromTimestamptz } = useDates();
  const [calculatedTransactionFees, setCalculatedTransactionFees] = useState<{
    price_billable_transaction_fees:
      | CalculateTransactionFeesInterface
      | null
      | undefined;
    price_estimate_transaction_fees:
      | CalculateTransactionFeesInterface
      | null
      | undefined;
  } | null>(null);
  const notify = useNotify();
  const [finishReservationMutation] = useFinishReservationMutation();

  // Fetching reservation manually because price_estimate_transaction_fees and price_bill_transaction_fees is incorreclty sanitized by ra-data-hasura plugin
  const { data: customReservationData } =
    useGetReservationById_Administrator_20220601Query({
      skip: !props?.record?.id,
      variables: {
        id: props?.record?.id,
      },
    });

  useEffect(() => {
    let calculated_price_estimate_transaction_fees;
    if (!props?.record?.price_estimate_transaction_fees) {
      calculated_price_estimate_transaction_fees = calculateTransactionFees({
        total_amount: props?.record?.price_estimate,
        total_amount_discounted: props?.record?.price_estimate_discounted,
        extra_cost: props?.record?.extra_cost,
      });
    }
    let calculated_price_billable_transaction_fees;
    if (!props?.record?.price_billable_transaction_fees) {
      calculated_price_billable_transaction_fees = calculateTransactionFees({
        total_amount: props?.record?.price_billable,
        total_amount_discounted: props?.record?.price_billable_discounted,
        extra_cost: props?.record?.extra_cost,
      });
    }
    setCalculatedTransactionFees({
      price_billable_transaction_fees:
        calculated_price_billable_transaction_fees,
      price_estimate_transaction_fees:
        calculated_price_estimate_transaction_fees,
    });
  }, []);

  const reservationDurationMutator: Mutator<Reservation, Reservation> = (
    args,
    state,
    utils
  ) => {
    const values = state.formState.values as Reservation;
    const starts_at = new Date(values.starts_at);
    const ends_at = new Date(values.ends_at);

    const careGivingDuration = differenceInHours(ends_at, starts_at);
    // TODO: Validate service type
    utils.changeValue(
      state,
      'metadata.caregiving_duration',
      () => careGivingDuration
    );
    if (values.metadata['duration_estimate']) {
      utils.changeValue(
        state,
        'metadata.duration_estimate',
        () => careGivingDuration
      );
    }
  };
  const reservationCaregivingDurationMutator: Mutator<
    Reservation,
    Reservation
  > = (args, state, utils) => {
    const values = state.formState.values as Reservation;

    utils.changeValue(state, 'metadata.duration_estimate', (val) => {
      return values.metadata.caregiving_duration;
    });
  };

  const reservationDurationBillableMutator: Mutator<
    Reservation,
    Reservation
  > = async (args, state, utils) => {
    const values = state.formState.values as Reservation;

    const started_at = new Date(values.started_at);
    const ended_at = new Date(values.ended_at);

    let careGivingDurationBillable = values.duration_estimate;

    if (started_at && ended_at) {
      careGivingDurationBillable = differenceInHours(ended_at, started_at);
      utils.changeValue(
        state,
        'duration_billable',
        () => careGivingDurationBillable
      );
    }

    const calculator = QuoteCalculator.withStrategy(values.metadata.service!);
    const travel = new Travel(
      {
        distanceBetween0AndA: values.metadata.distance_between_0_and_a,
        distanceBetween0AndB: values.metadata.distance_between_0_and_b,
        distanceBetweenAAndB: values.metadata.distance_between_a_and_b,
      },
      values.metadata.type_of_travel === 'TWO_WAY'
    );

    const cost = calculator.calculate({
      travel,
      caregivingDuration: careGivingDurationBillable,
      city: values.metadata.service_city,
    });

    utils.changeValue(state, 'price_billable', () =>
      cost.getCost().getRoundedValue()
    );

    utils.resetFieldState('price_billable');

    let discountedCost: null | Cost = null;

    if (values.voucher_code) {
      if (values.voucher_code === 'AL_PROFILO') {
        discountedCost = cost.applyPercentDiscount(
          props?.record?.reservor?.discount_percent
        );
      } else {
        const voucher = await dataProvider.getOne('voucher', {
          code: values?.voucher_code as string,
        });

        if (voucher?.percent_value) {
          discountedCost = cost.applyPercentDiscount(voucher?.percent_value);
        }
      }
    }

    if (discountedCost) {
      utils.changeValue(state, 'price_billable_discounted', () =>
        discountedCost?.getRawValue()
      );
      utils.resetFieldState('price_billable_discounted');
    }
  };
  const { getDistanceAndDurationInputBySelectedService } =
    useQuoteCalculations();
  const statusRef = useRef<any>();

  const [
    isAddMissingOverdueReservationDataModalOpen,
    setIsAddMissingOverdueReservationDataModalOpen,
  ] = useState(false);

  const {
    refetch: getDistanceAndDuration,
    loading: loadingDistanceAndDuration,
  } = useGetDistanceAndDurationQuery({
    skip: true,
    context: {
      [HasuraSessionVariables.ActiveRole]: Roles.Administrator,
    },
  });

  const reservationServiceTypeMutator: Mutator<
    Reservation,
    Reservation
  > = async (args, state, utils) => {
    const values = state.formState.values as Reservation;

    const service = values.service_id;
    utils.changeValue(state, 'metadata.service', () => service);
  };

  const reservationStatusMutator: Mutator<Reservation, Reservation> = async (
    args,
    state,
    utils
  ) => {
    const newStatus = state.fields.status.lastFieldState
      ?.value as Reservation_Status_Enum;

    const oldStatus = state.fields.status.lastFieldState
      ?.initial as Reservation_Status_Enum;

    if (
      oldStatus === Reservation_Status_Enum.Canceled &&
      newStatus === Reservation_Status_Enum.Waiting
    ) {
      try {
        create(EmailWillBeSentOutAlert)().then((result) => {
          if (!result) {
            utils.changeValue(
              state,
              'status',
              () => Reservation_Status_Enum.Canceled
            );
          }
        });
      } catch (e) {
        console.log('Reject');
      }
    }

    const driverExists = !!state.fields.driver_id.lastFieldState?.value;

    if (
      oldStatus === Reservation_Status_Enum.Shelved &&
      newStatus === Reservation_Status_Enum.Accepted &&
      !driverExists
    ) {
      utils.changeValue(state, 'status', () => Reservation_Status_Enum.Shelved);
      notify('Assign the driver first, then change to ACCEPTED', 'error');
    }

    if (
      oldStatus === Reservation_Status_Enum.Shelved &&
      newStatus === Reservation_Status_Enum.Waiting &&
      driverExists
    ) {
      utils.changeValue(state, 'status', () => Reservation_Status_Enum.Shelved);
      notify('Remove the driver first, then change status to WAITING', 'error');
    }

    if (
      oldStatus === Reservation_Status_Enum.FinishReservationOverdue &&
      newStatus === Reservation_Status_Enum.Finished
    ) {
      setIsAddMissingOverdueReservationDataModalOpen(true);
    }
  };

  const reservationDurationEstimateMutator: Mutator<
    Reservation,
    Reservation
  > = async (args, state, utils) => {
    const { starts_at, ends_at, metadata } = state.formState
      .values as Reservation;

    const careGivingDuration = getDurationInHours(
      fromTimestamptz(ends_at),
      fromTimestamptz(starts_at)
    );

    utils.changeValue(
      state,
      'metadata.caregiving_duration',
      () => careGivingDuration
    );

    if (metadata['duration_estimate']) {
      utils.changeValue(
        state,
        'metadata.duration_estimate',
        () => careGivingDuration
      );
    }

    notify('Duration updated, please recalculate if needed', 'info');
  };

  const confirmDriverSuggestedReservationModifications = async (
    form: FormWithRedirectProps['form'],
    record: any
  ) => {
    setShowBlockingOverlay(true);
    // started_at,
    form?.change('started_at', record.driver_suggested_started_at);
    // ended_at,
    form?.change('ended_at', record.driver_suggested_ended_at);

    // duration_billable,
    const careGivingDurationBillable =
      differenceInMinutes(
        new Date(record.driver_suggested_ended_at),
        new Date(record.driver_suggested_started_at)
      ) / 60;

    form?.change('duration_billable', careGivingDurationBillable);

    // price_billable,
    const calculator = QuoteCalculator.withStrategy(record.metadata.service!);
    const travel = new Travel(
      {
        distanceBetween0AndA: record.metadata.distance_between_0_and_a,
        distanceBetween0AndB: record.metadata.distance_between_0_and_b,
        distanceBetweenAAndB: record.metadata.travel_distance,
      },
      record.metadata.type_of_travel === 'TWO_WAY'
    );
    const cost = calculator.calculate({
      travel,
      caregivingDuration: careGivingDurationBillable,
      city: record.metadata.service_city,
    });

    form?.change('price_billable', cost.getCost().getRoundedValue());

    save(form?.getState().values, undefined, {
      onSuccess: async () => {
        try {
          await finishReservationMutation({
            variables: {
              reservation_id: record.id,
              feedback_link: `${process.env.REACT_APP_APP_URL}/{user_type}/reservations/{reservation_id}/rate`,
            },
            context: {
              [HasuraSessionVariables.ActiveRole]: Roles.Administrator,
            },
          });
          refresh();
          setShowBlockingOverlay(false);
          notify('Operation completed successfully', 'success');
        } catch (e) {
          refresh();
          setShowBlockingOverlay(false);
          console.error(e);
          notify('Operation unsuccessful. Check console.log', 'error');
        }
      },
      onFailure: (e) => console.log(e),
    });
  };

  const calculatePrice = async (form: FormWithRedirectProps['form']) => {
    const formState = form.getState();

    const values = formState.values as Reservation;

    const updatedValues = { ...formState.values } as Reservation;

    let is_provided_in_city,
      travel_duration,
      travel_distance,
      shortest_distance_from_city_center,
      distance_between_0_and_a,
      distance_between_0_and_b;

    if (
      formState.values.metadata.departure_address &&
      formState.values.metadata.destination_address &&
      formState.values.service_id !== ServicesEnum.PhoneAssistance
    ) {
      const queryInput = getDistanceAndDurationInputBySelectedService({
        departure: {
          lat: parseFloat(formState.values.metadata.departure_lat),
          lng: parseFloat(formState.values.metadata.departure_lng),
        },
        destination: {
          lat: parseFloat(formState.values.metadata.destination_lat),
          lng: parseFloat(formState.values.metadata.destination_lng),
        },
        serviceCity: formState.values.metadata.service_city,
        service: formState.values.service_id,
      });

      const { data: distanceAndDurationData } = await getDistanceAndDuration(
        queryInput as any
      );

      const distanceData = calculateFinalTravelDistanceAndDuration(
        distanceAndDurationData,
        updatedValues.metadata
      );

      is_provided_in_city = distanceData.is_provided_in_city;
      travel_duration = distanceData.travel_duration;
      travel_distance = distanceData.travel_distance;
      shortest_distance_from_city_center =
        distanceData.shortest_distance_from_city_center;

      distance_between_0_and_a =
        distanceAndDurationData?.getDistanceAndDuration?.a0?.distance
          ?.kilometers || 0;
      distance_between_0_and_b =
        distanceAndDurationData?.getDistanceAndDuration?.b0?.distance
          ?.kilometers || 0;

      form.change('metadata.is_provided_in_city', is_provided_in_city);
      form.change('metadata.travel_duration', travel_duration);
      form.change('metadata.travel_distance', travel_distance);
      form.change(
        'metadata.shortest_distance_from_city_center',
        shortest_distance_from_city_center
      );
    }
    const calculator = QuoteCalculator.withStrategy(values.metadata.service!);
    const travel = new Travel(
      {
        distanceBetween0AndA: distance_between_0_and_a,
        distanceBetween0AndB: distance_between_0_and_b,
        distanceBetweenAAndB: travel_distance,
      },
      values.metadata.type_of_travel === 'TWO_WAY'
    );
    const cost = calculator.calculate({
      travel,
      caregivingDuration: updatedValues.metadata.caregiving_duration,
      city: values.metadata.service_city,
    });

    form.change(
      'price_estimate',
      cost.getCost().getRoundedValue() + (values.extra_costs_value || 0)
    );

    form.resetFieldState('price_estimate');

    let discountedCost: null | Cost = null;

    if (values.voucher_code) {
      if (values.voucher_code === 'AL_PROFILO') {
        discountedCost = cost.applyPercentDiscount(
          props?.record?.reservor?.discount_percent
        );
      } else {
        const voucher = await dataProvider.getOne('voucher', {
          code: values?.voucher_code as string,
        });

        if (voucher?.data.discount_percent) {
          discountedCost = cost.applyPercentDiscount(
            voucher?.data.percent_value
          );
        }
      }

      if (discountedCost !== null) {
        form.change(
          'price_estimate_discounted',
          discountedCost.getRoundedValue()
        );
        form.resetFieldState('price_estimate_discounted');
      }
    }
    const calculatedEstimateTransactionFees = calculateTransactionFees({
      total_amount: cost.getCost().getRoundedValue(),
      total_amount_discounted: discountedCost?.getRoundedValue(),
      extra_cost: props?.record?.extra_cost,
    });

    form.change(
      'price_estimate_transaction_fees',
      calculatedEstimateTransactionFees
    );
  };

  const reservationPriceBillableMutator: Mutator<
    Reservation,
    Reservation
  > = async (args, state, utils) => {
    const values = state.formState.values as Reservation;

    // let careGivingDurationBillable = values.duration_billable || 0;
    const calculator = QuoteCalculator.withStrategy(values.metadata.service!);
    const travel = new Travel(
      {
        distanceBetween0AndA: values.metadata.distance_between_0_and_a,
        distanceBetween0AndB: values.metadata.distance_between_0_and_b,
        distanceBetweenAAndB: values.metadata.travel_distance,
      },
      values.metadata.type_of_travel === 'TWO_WAY'
    );
    const cost = calculator.calculate({
      travel,
      caregivingDuration: values.metadata.caregiving_duration,
      city: values.metadata.service_city,
    });
    utils.changeValue(
      state,
      'price_billable',
      () => cost.getCost().getRoundedValue() + (values.extra_costs_value || 0)
    );

    utils.resetFieldState('price_billable');

    let discountedCost: null | Cost = null;

    if (values.voucher_code) {
      if (values.voucher_code === 'AL_PROFILO') {
        console.log('here');
        discountedCost = cost.applyPercentDiscount(
          props?.record?.reservor?.discount_percent
        );
        console.log(discountedCost.getRoundedValue());
      } else {
        const voucher = await dataProvider.getOne('voucher', {
          code: values?.voucher_code as string,
        });

        if (voucher?.data.discount_percent) {
          discountedCost = cost.applyPercentDiscount(
            voucher?.data.percent_value
          );
        }
      }

      if (discountedCost !== null) {
        utils.changeValue(state, 'price_billable_discounted', () =>
          discountedCost?.getRawValue()
        );
        utils.resetFieldState('price_billable_discounted');
      }
    }
  };

  const [resendStripeCheckoutSessionPaymentRequestMutation] =
    useResendStripeCheckoutSessionPaymentRequestMutation();

  const resendStripeCheckoutSessionPaymentRequest = async () => {
    try {
      const response = await resendStripeCheckoutSessionPaymentRequestMutation({
        variables: { reservation_id: props.record.id },
      });

      if (response?.data?.resendStripeCheckoutSessionPaymentRequest?.success) {
        notify('Payment request sent successfully', 'success');
      } else {
        notify('Payment request not sent', 'error');
      }
    } catch (e: any) {
      console.error(e);
      notify('Payment request not sent: ' + e?.message, 'error');
    }
  };

  return (
    <FormWithRedirect
      {...props}
      mutators={{
        updateReservationCaregivingDuration:
          reservationCaregivingDurationMutator,
        updateReservationDuration: reservationDurationMutator,
        updateReservationPriceBillable: reservationPriceBillableMutator,
        updateReservationDurationBillable: reservationDurationBillableMutator,
        updateStatus: reservationStatusMutator,
        updateReservationDates: reservationDurationEstimateMutator,
        updateServiceType: reservationServiceTypeMutator,
      }}
      render={({ record, form, ...formProps }) => {
        return (
          <form>
            <Box p="1em">
              <AddMissingOverdueReservationDataModal
                isOpen={isAddMissingOverdueReservationDataModalOpen}
                onResolve={() => {
                  setIsAddMissingOverdueReservationDataModalOpen(false);
                }}
                onReject={() => {
                  form.change(
                    'status',
                    Reservation_Status_Enum.FinishReservationOverdue
                  );
                  setIsAddMissingOverdueReservationDataModalOpen(false);
                }}
                form={form}
                {...formProps}
              />
              <Box display="flex">
                <Box flex={2} mr="1em">
                  <Box display="flex">
                    <Typography variant="h6" gutterBottom>
                      Reservation
                    </Typography>
                    {record?.is_manual && (
                      <Box ml="auto">
                        <b>
                          <Chip
                            color="primary"
                            label="Created by customer care"
                          />
                        </b>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <hr />
                    <b>ID:</b> {record?.id}
                    <br />
                    <b>Short ID:</b>{' '}
                    {record?.short_id ? record?.short_id : 'N/A'}
                    <hr />
                  </Box>
                  <Box mb="0.5em">
                    <Box display="flex">
                      <Box flex={0.5}>
                        <SelectInput
                          source="service_id"
                          choices={reservationServiceData}
                          fullWidth
                          onChange={form.mutators.updateServiceType}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box display="flex">
                      <Box flex={0.5} mr="0.5em">
                        <Box>
                          <Typography
                            variant="h6"
                            style={{ fontSize: 12, fontWeight: 'bold' }}
                          >
                            Estimate
                          </Typography>
                          <Box>
                            <NumberInput
                              label="Price estimate in EUR"
                              fullWidth
                              source="price_estimate"
                            />
                          </Box>
                          {record?.price_estimate_discounted ||
                          record?.voucher_redemption_id ? (
                            <Box>
                              <NumberInput
                                label="Discounted price estimate in EUR"
                                fullWidth
                                source="price_estimate_discounted"
                                disabled
                              />
                            </Box>
                          ) : null}
                        </Box>
                        {record?.status === Reservation_Status_Enum.Finished ||
                        record?.status ===
                          Reservation_Status_Enum.FinishReservationOverdue ||
                        record?.status ===
                          Reservation_Status_Enum.PaymentInProgress ||
                        record?.status ===
                          Reservation_Status_Enum.PaymentFailed ? (
                          <Box>
                            <Typography
                              variant="h6"
                              style={{ fontSize: 12, fontWeight: 'bold' }}
                            >
                              Billable
                            </Typography>
                            <Box>
                              <NumberInput
                                label="Price billable in EUR"
                                fullWidth
                                source="price_billable"
                                disabled
                              />
                            </Box>
                            {record?.price_billable_discounted ||
                            record?.voucher_redemption_id ? (
                              <Box>
                                <NumberInput
                                  label="Discounted price billable in EUR"
                                  fullWidth
                                  source="price_billable_discounted"
                                  disabled
                                />
                              </Box>
                            ) : null}
                          </Box>
                        ) : null}
                        <Box>
                          <Typography
                            variant="h6"
                            style={{ fontSize: 12, fontWeight: 'bold' }}
                          >
                            Extra
                          </Typography>
                          <NumberInput
                            label="Extra cost"
                            fullWidth
                            source="extra_costs_value"
                            disabled
                          />
                          <Labeled
                            resource="reservation"
                            record={record}
                            label="Extra cost details"
                          >
                            <TextField
                              source="extra_costs_text"
                              record={record}
                              emptyText="n/a"
                            />
                          </Labeled>
                        </Box>
                      </Box>
                      <Box flex={0.5} ml="0.5em">
                        <Box>
                          <Labeled
                            resource="reservation"
                            record={record}
                            label="Voucher"
                          >
                            <TextField
                              source="voucher_code"
                              record={record}
                              emptyText="n/a"
                            />
                          </Labeled>
                        </Box>
                        {record?.voucher_not_applicable_reason ? (
                          <Box>
                            <Labeled
                              resource="reservation"
                              record={record}
                              label="Voucher not applicable reason"
                            >
                              <TextField
                                source="voucher_not_applicable_reason"
                                record={record}
                                emptyText="n/a"
                              />
                            </Labeled>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                  <hr />
                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <DateTimeInput
                        disabled={record?.ended_at}
                        source="starts_at"
                        emptyText="n/a"
                        fullWidth
                        onChange={form.mutators.updateReservationDates}
                      />
                    </Box>
                    <Box flex={1} ml="0.5em">
                      <DateTimeInput
                        disabled={record?.ended_at}
                        source="ends_at"
                        emptyText="n/a"
                        fullWidth
                        onChange={form.mutators.updateReservationDates}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" mb="1em">
                    <Box flex={0.5} mr="0.5em">
                      <Tooltip
                        title="Calculated based on start and end time"
                        placement="top"
                      >
                        <Box display="inline">
                          <NumberInput
                            fullWidth
                            onChange={
                              form.mutators.updateReservationCaregivingDuration
                            }
                            source="metadata.caregiving_duration"
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                    {record?.metadata.duration_estimate && (
                      <Box flex={0.5} ml="0.5em">
                        <Tooltip
                          title="Calculated based on start and end time"
                          placement="top"
                        >
                          <Box display="inline">
                            <NumberInput
                              label="Duration estimate"
                              source="metadata.duration_estimate"
                              fullWidth
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                  <hr />
                  <Box>
                    <SelectInput
                      source="metadata.service_city"
                      choices={SUPPORTED_CITIES.map((c) => ({
                        id: c.name,
                        name: c.name,
                      }))}
                    />
                    <LocationInput
                      sourceObject="metadata"
                      addressField="departure_address"
                      latField="departure_lat"
                      lngField="departure_lng"
                    />
                  </Box>
                  <Box>
                    <LocationInput
                      sourceObject="metadata"
                      addressField="destination_address"
                      latField="destination_lat"
                      lngField="destination_lng"
                    />
                  </Box>
                  <hr />
                  <Box mt="1em" />
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Driver
                    </Typography>
                    <ReferenceInput
                      source="driver_id"
                      reference="account"
                      fullWidth
                      allowEmpty
                      perPage={20}
                      filterToQuery={(searchText) => ({
                        email: searchText,
                        'deleted_at@_is_null': true,
                      })}
                      record={record}
                      basePath="/account"
                    >
                      <AutocompleteInput optionText="email" />
                    </ReferenceInput>
                  </Box>
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Cancellation reasons
                    </Typography>
                  </Box>
                  <Box>
                    <Labeled
                      resource="reservation"
                      record={record}
                      label="Patient cancellation reason"
                    >
                      <TextField
                        source="patient_cancellation_reason"
                        record={record}
                        emptyText="n/a"
                      />
                    </Labeled>
                    <br />
                    <br />
                    <Labeled
                      resource="reservation"
                      record={record}
                      label="Driver cancellation reasons"
                    >
                      <TextField
                        source="driver_cancellation_reasons"
                        record={record}
                        emptyText="n/a"
                      />
                    </Labeled>
                  </Box>
                  <br />
                  <Box display="flex" flexWrap="wrap">
                    <Box width={1 / 2}>
                      <Typography variant="h6" gutterBottom>
                        Reservee
                      </Typography>
                      <code>
                        <pre>
                          {record?.reservee_metadata ? (
                            JSON.stringify(record.reservee_metadata, null, 2)
                          ) : (
                            <Typography variant="caption">n/a</Typography>
                          )}
                        </pre>
                      </code>
                    </Box>
                    <Box width={1 / 2}>
                      <Typography variant="h6" gutterBottom>
                        Reservor
                      </Typography>
                      <code>
                        <pre>
                          {record?.reservor_metadata ? (
                            JSON.stringify(record.reservor_metadata, null, 2)
                          ) : (
                            <Typography variant="caption">n/a</Typography>
                          )}
                        </pre>
                      </code>
                    </Box>
                    <Box width={1}>
                      <Typography variant="h6" gutterBottom>
                        Reservation metadata
                      </Typography>
                      <code>
                        <pre>
                          {record?.metadata ? (
                            JSON.stringify(
                              {
                                ...record.metadata,
                                preferred_driver:
                                  record.preferred_driver || '-',
                              },
                              null,
                              2
                            )
                          ) : (
                            <Typography variant="caption">n/a</Typography>
                          )}
                        </pre>
                      </code>
                      <Box width={1}>
                        <Typography variant="h6" gutterBottom>
                          Estimate transaction fees
                        </Typography>
                        {calculatedTransactionFees?.price_estimate_transaction_fees &&
                          !customReservationData?.reservation_by_pk
                            ?.price_estimate_transaction_fees && (
                            <div>
                              <h6>
                                Calculated by frontend. Estimated, non final
                                values.
                              </h6>
                              <code>
                                <pre>
                                  {JSON.stringify(
                                    {
                                      ...sortObjectKeysAlphabetically(
                                        calculatedTransactionFees?.price_estimate_transaction_fees
                                      ),
                                    },
                                    null,
                                    2
                                  )}
                                </pre>
                              </code>
                            </div>
                          )}

                        {customReservationData?.reservation_by_pk
                          ?.price_estimate_transaction_fees && (
                          <div>
                            <h6>Calculated by backend. Actual values.</h6>
                            <code>
                              <pre>
                                {JSON.stringify(
                                  {
                                    ...sortObjectKeysAlphabetically(
                                      customReservationData?.reservation_by_pk
                                        ?.price_estimate_transaction_fees
                                    ),
                                  },
                                  null,
                                  2
                                )}
                              </pre>
                            </code>
                          </div>
                        )}
                      </Box>
                      <Box width={1}>
                        <Typography variant="h6" gutterBottom>
                          Billable transaction fees
                        </Typography>
                        {record?.status === Reservation_Status_Enum.Finished ||
                        record?.status ===
                          Reservation_Status_Enum.FinishReservationOverdue ||
                        record?.status ===
                          Reservation_Status_Enum.PaymentFailed ||
                        record?.status ===
                          Reservation_Status_Enum.PaymentInProgress ? (
                          calculatedTransactionFees?.price_billable_transaction_fees &&
                          !customReservationData?.reservation_by_pk
                            ?.price_billable_transaction_fees && (
                            <div>
                              <h6>
                                Calculated by frontend. Estimated, non final
                                values.
                              </h6>
                              <code>
                                <pre>
                                  {JSON.stringify(
                                    {
                                      ...sortObjectKeysAlphabetically(
                                        calculatedTransactionFees?.price_billable_transaction_fees
                                      ),
                                    },
                                    null,
                                    2
                                  )}
                                </pre>
                              </code>
                            </div>
                          )
                        ) : (
                          <small>
                            No billable price data yet. Reservation must have
                            one of the statuses: Finished,
                            FinishReservationOverdue, PaymentFailed, or
                            PaymentInProgress
                          </small>
                        )}

                        {customReservationData?.reservation_by_pk
                          ?.price_billable_transaction_fees && (
                          <div>
                            <h6>Calculated by backend. Actual values.</h6>
                            <code>
                              <pre>
                                {JSON.stringify(
                                  {
                                    ...sortObjectKeysAlphabetically(
                                      customReservationData?.reservation_by_pk
                                        ?.price_billable_transaction_fees
                                    ),
                                  },
                                  null,
                                  2
                                )}
                              </pre>
                            </code>
                          </div>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box flex={1} ml="1em">
                  <Typography variant="h6">Status</Typography>
                  <SelectInput
                    inputRef={statusRef}
                    source="status"
                    choices={reservationData}
                    fullWidth
                    onChange={form.mutators.updateStatus}
                  />
                  {record?.status !== Reservation_Status_Enum.Finished &&
                    record?.is_manual && (
                      <Box mb={2}>
                        <Typography
                          variant="h6"
                          style={{ fontSize: 12, fontWeight: 'bold' }}
                        >
                          Resend a payment request link
                        </Typography>
                        <Box mb={1}>
                          <small>
                            This reservation is manual and not yet finished. You
                            can resend the payment request email.
                          </small>
                        </Box>
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                          onClick={(e) => {
                            e.preventDefault();
                            resendStripeCheckoutSessionPaymentRequest();
                          }}
                          label="Resend"
                        />
                      </Box>
                    )}
                  {record?.created_at && (
                    <Box>
                      <Labeled
                        resource="reservation"
                        record={record}
                        label="Created at"
                      >
                        <DateField
                          showTime
                          source="created_at"
                          record={record}
                        />
                      </Labeled>
                    </Box>
                  )}
                  {record?.updated_at && (
                    <Box>
                      <Labeled
                        resource="reservation"
                        record={record}
                        label="Updated at"
                      >
                        <DateField
                          showTime
                          source="updated_at"
                          record={record}
                        />
                      </Labeled>
                    </Box>
                  )}
                  {(record?.started_at || record?.ended_at) && (
                    <Box>
                      <hr />
                      {record?.started_at && (
                        <Box>
                          <Labeled
                            resource="reservation"
                            record={record}
                            label="Started at"
                          >
                            <DateField
                              showTime
                              source="started_at"
                              record={record}
                            />
                          </Labeled>
                        </Box>
                      )}
                      {record?.ended_at && (
                        <Box>
                          <Labeled
                            resource="reservation"
                            record={record}
                            label="Ended at"
                          >
                            <DateField
                              showTime
                              source="ended_at"
                              record={record}
                            />
                          </Labeled>
                        </Box>
                      )}
                    </Box>
                  )}
                  {record?.status === Reservation_Status_Enum.Shelved && (
                    <Box>
                      <hr />
                      <Alert severity="warning">
                        <b>Action required</b>
                        <br /> This reservation has to be manually assigned to a
                        fresh, new driver in 30 minutes since the created_at
                        date. Once assigned, you have to change the status from
                        SHELVED to ACCEPTED and click {'"Save"'} ASAP.
                        <br />
                        <br />
                        Note: You have to call the driver and ask him/her to
                        accept the reservation.
                        <br />
                        <br />
                        If the reservation is not accepted in 30 minutes, it
                        will be automatically moved to WAITING reservations (be
                        careful with assigning new values around 30 minutes from
                        the created_at date).
                      </Alert>
                    </Box>
                  )}
                  {record?.status ===
                    Reservation_Status_Enum.FinishReservationOverdue &&
                    !!record?.driver_suggested_ended_at &&
                    !!record?.driver_suggested_started_at && (
                      <Box>
                        <hr />
                        <Alert severity="warning">
                          <b>Action required</b>
                          <br /> Driver has submitted start and end details.{' '}
                          Review and confirm.
                        </Alert>
                        <Box>
                          <Labeled
                            resource="reservation"
                            record={record}
                            label="Driver suggested started at"
                          >
                            <DateField
                              showTime
                              source="driver_suggested_started_at"
                              record={record}
                            />
                          </Labeled>
                        </Box>
                        <Box>
                          <Labeled
                            resource="reservation"
                            record={record}
                            label="Driver suggested ended at"
                          >
                            <DateField
                              showTime
                              source="driver_suggested_ended_at"
                              record={record}
                            />
                          </Labeled>
                        </Box>
                        <Box mb={2}>
                          <Box mb={1}>
                            <small>
                              Once you confirm, reservation will be updated and
                              the system will attempt to bill the customer.
                            </small>
                          </Box>
                          <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={(e) => {
                              e.preventDefault();
                              confirmDriverSuggestedReservationModifications(
                                form,
                                record
                              );
                            }}
                            label="Confirm and finish the service"
                          />
                        </Box>
                      </Box>
                    )}
                  <hr />
                  <Box>
                    <TextInput
                      source="administrator_note"
                      multiline={true}
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <Box mb={1} fontSize={14}>
                      Automatically calculate distance and price based on
                      current inputs.
                    </Box>

                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        calculatePrice(form);
                      }}
                      label="Calculate"
                      icon={<Computer />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <BlockingOverlay show={showBlockingOverlay} />
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton
                  redirect={false}
                  disabled={formProps.pristine}
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </Box>
            </Toolbar>
          </form>
        );
      }}
    />
  );
};
