import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  Filter,
  TextInput,
  DateInput,
} from 'react-admin';
import * as React from 'react';
import { Link } from 'react-router-dom';

const PenaltyFilter = (props: any) => (
  <div>
    <Filter {...props}>
      <TextInput
        source="reservation_id@_ilike"
        label="Reservation ID"
        alwaysOn
      />
      <TextInput source="account_email@_ilike" label="Account Email" alwaysOn />
      <DateInput source="created_at@_gte" label="From" alwaysOn />
      <DateInput source="created_at@_lte" label="To" alwaysOn />
    </Filter>
  </div>
);

export const AccountPenaltyList = (props: any) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      filters={<PenaltyFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="account_id" />
        <TextField source="reservation_id" />
        <TextField source="type" />
        <DateField source="created_at" showTime />
        <TextField source="status" />
        <FunctionField
          render={(record: any) => (
            <Link to={`/account_penalty/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List>
  );
};
