import * as React from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  downloadCSV,
  ExportButton,
  Filter,
  FunctionField,
  List,
  ResourceComponentProps,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { GLOBAL_EXPORTER_SETTINGS, SUPPORTED_CITIES } from '@ugo/data';
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../shared/TabPanel';
import { useState } from 'react';
import {
  driverProfileExporter,
  DriverProfilesList,
} from './DriverProfilesList';
import {
  Driver_Profile,
  Vw_Admin_Driver_Profile,
} from '@ugo/graphql-operations';
import { flattenObjectWithParentKeys } from '@ugo/utils';
import { unparse as convertToCSV } from 'papaparse';

const DFilter = (props: any) => {
  return (
    <div>
      <Filter {...props}>
        <TextInput source="email@_ilike" label="Email" alwaysOn />
        <TextInput source="last_name@_ilike" label="Last name" alwaysOn />
        <TextInput
          source="voucherify_customer_id@_ilike"
          label="Voucherify ID"
        />
        <SelectInput
          source="cities@_has_key"
          label="City"
          choices={SUPPORTED_CITIES.map((c) => ({ name: c.name, id: c.name }))}
          alwaysOn
        />
      </Filter>
    </div>
  );
};

export const DriverList = (props: ResourceComponentProps) => {
  const [value, setValue] = useState(0);
  return (
    <Box>
      <Paper>
        <Tabs
          value={value}
          indicatorColor="primary"
          color="white"
          textColor="primary"
          onChange={(e, v) => setValue(v)}
          aria-label="simple tabs example"
        >
          <Tab label="Drivers" />
          <Tab label="Driver profiles (DOF data)" />
          <Tab label="Driver profiles (Full export)" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <List
            resource="vw_admin_account"
            basePath={props?.match?.url}
            bulkActionButtons={false}
            {...props}
            filters={<DFilter />}
            actions={<DriverActions />}
            filterDefaultValues={{
              is_driver: true,
              'deleted_at@_is_null': true,
            }}
            sort={{
              field: 'created_at',
              order: 'DESC',
            }}
          >
            <Datagrid>
              <TextField source="email" />
              <TextField
                source="personal_data.first_name"
                emptyText="n/a"
                label="First name"
              />
              <TextField
                source="personal_data.last_name"
                emptyText="n/a"
                label="Last name"
              />
              <DateField source="created_at" />

              <FunctionField
                render={(record: any) => (
                  <Link to={`/account/${record.id}`}>VIEW</Link>
                )}
              />
            </Datagrid>
          </List>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DriverProfilesList />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <List
            {...props}
            resource="vw_admin_driver_profile"
            basePath={props?.match?.url}
            bulkActionButtons={false}
            exporter={driverProfileExporterFull}
            filters={<DFilter />}
            actions={<DriverActions />}
            sort={{
              field: 'created_at',
              order: 'DESC',
            }}
          >
            <Datagrid>
              <TextField source="email" />
              <TextField
                source="personal_data.first_name"
                emptyText="n/a"
                label="First name"
              />
              <TextField
                source="personal_data.last_name"
                emptyText="n/a"
                label="Last name"
              />
              <DateField source="created_at" />

              <FunctionField
                render={(record: any) => (
                  <Link to={`/account/${record.id}`}>VIEW</Link>
                )}
              />
            </Datagrid>
          </List>
        </TabPanel>
      </Paper>
    </Box>
  );
};

export const driverProfileExporterFull = async (
  driverProfiles: Vw_Admin_Driver_Profile[]
) => {
  const _driverProfiles = driverProfiles.map((driverProfile) => {
    delete driverProfile.activity_log;
    if (driverProfile.is_typeform_documents_submitted) {
      console.log(driverProfile);
    }

    const object: any = {
      account_id: driverProfile.account_id,
      email: driverProfile.email,
      iban_connected: driverProfile.iban_connected,
      status: driverProfile.status,
      created_at: driverProfile.created_at,
      is_general_info_submitted: driverProfile.is_general_info_submitted,
      is_video_verification_submitted:
        driverProfile.is_video_verification_submitted,
      is_video_verification_approved:
        driverProfile.is_video_verification_approved,
      is_academy_completed: driverProfile.is_academy_completed,
      is_covid_passport_submitted: driverProfile.is_covid_passport_submitted,
      is_covid_passport_approved: driverProfile.is_covid_passport_approved,
      dof_requested_city: driverProfile.dof_requested_city,
      is_typeform_documents_submitted:
        driverProfile.is_typeform_documents_submitted,
      is_typeform_documents_approved:
        driverProfile.is_typeform_documents_approved,
      cities: driverProfile.cities ? JSON.stringify(driverProfile.cities) : '',
      services: driverProfile.services
        ? JSON.stringify(driverProfile.services)
        : '',
      covid_passport_payload: driverProfile.covid_passport_payload
        ? JSON.stringify(driverProfile.covid_passport_payload)
        : '',
      general_info_payload: driverProfile.general_info_payload
        ? JSON.stringify(driverProfile.general_info_payload)
        : '',
      typeform_documents_payload: driverProfile.typeform_documents_payload
        ? JSON.stringify(driverProfile.typeform_documents_payload)
        : '',
      document_verification_driver_license_snapshot:
        driverProfile.document_verification_driver_license_snapshot
          ? JSON.stringify(
              driverProfile.document_verification_driver_license_snapshot
            )
          : '',
      document_verification_id_snapshot:
        driverProfile.document_verification_id_snapshot
          ? JSON.stringify(driverProfile.document_verification_id_snapshot)
          : '',
    };

    return object;
  });

  // const objectWithMaxKeys = _driverProfiles.reduce((a, b) =>
  //   Object.keys(a).length > Object.keys(b).length ? a : b
  // );

  // Hacking
  const csv = convertToCSV({ data: _driverProfiles }, { delimiter: ';' });

  downloadCSV(csv, 'driver_profiles_' + Date.now());
};

const DriverActions = (props: any) => {
  const { className, basePath, total, resource, currentSort, filters } = props;

  const { showFilter, displayedFilters, filterValues, exporter } =
    useListContext();

  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        exporter={exporter || undefined}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};
