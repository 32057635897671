import React, { useCallback, useEffect, useState } from 'react';
import {
  Edit,
  FormWithRedirect,
  Labeled,
  NumberInput,
  SaveButton,
  TextField,
  TextInput,
  TopToolbar,
  useGetList,
  UseGetOneHookValue,
  useMutation,
  DateInput,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import {
  GetAccountDriverQuery,
  useGetAccountDriverQuery,
} from '@ugo/graphql-operations';
import {
  Box,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { LocalTaxiOutlined } from '@material-ui/icons';
import { DriverSettingsDialog } from './DriverSettingsDialog';
import AccountMetadata, { accountFeedbackFields } from './AccountMetadata';
import FirebaseUserDetails from './FirebaseUserDetails';
import DriverOnboardingFlowData from './DriverOnboardingFlowData';
import { BooleanInput, FunctionField } from 'ra-ui-materialui';
import { AccountEmailChangeDialog } from './AccountEmailChangeDialog';
import { DeactivateDriverDialog } from './DeactivateDriverDialog';
import { DriverAdminStatusesEnum } from 'libs/data/src/driver-admin-statuses';

const ADMIN_SUPPORTED_TAX_REGIMES = [
  {
    value: 'WITHHOLDING',
    label: 'Ritenuta',
  },
  {
    value: 'FLAT_RATE_TAX',
    label: 'IVA Forfettaria',
  },
  {
    value: 'STANDARD_VAT',
    label: 'IVA Ordinaria',
  },
  {
    value: 'MINIMAL_VAT',
    label: 'IVA Minimi',
  },
  {
    value: 'ORDINARY_SOLE_PROPRIETORSHIP',
    label: 'Impresa Individuale Ordinaria',
  },
  {
    value: 'COMPANY',
    label: 'Società',
  },
  {
    value: 'FLAT_RATE_SOLE_PROPRIETORSHIP',
    label: 'Impresa Individuale Forfettaria',
  },
];

const ADMIN_SUPPORTED_RESIDENCE_PERMITS = [
  {
    value: 'NOT_NECESSARY',
    label: 'Non necessario',
  },
  {
    value: 'CARTA',
    label: 'Carta',
  },
  {
    value: 'PERMESSO',
    label: 'Permesso',
  },
  {
    value: 'PERMESSO_DI_SOGGIORNO_CE_PER_SOGGIORNANTI_DI_LUNGO_PERIODO',
    label: 'Permesso di soggiorno ce per soggiornanti di lungo periodo',
  },
  {
    value: 'IN_RINNOVO',
    label: 'In rinnovo',
  },
  {
    value: 'IN_ATTESA_DI_PERMESSO',
    label: 'In attesa di permesso',
  },
  {
    value: 'CARTA_PERMANENTE',
    label: 'Carta permanente',
  },
  {
    value: 'ALTRO_PROVVEDIMENTO',
    label: 'Altro provvedimento',
  },
];

const ADMIN_SUPPORTED_STATUS = [
  {
    value: DriverAdminStatusesEnum.Activated,
    label: 'Attivato',
  },
  {
    value: DriverAdminStatusesEnum.Deactivated,
    label: 'Disattivato',
  },
  {
    value: DriverAdminStatusesEnum.Suspended,
    label: 'Sospeso',
  },
  {
    value: DriverAdminStatusesEnum.Penalty,
    label: 'Penale',
  },
  {
    value: DriverAdminStatusesEnum.SuspendedB2B,
    label: 'Sospeso B2B - Partita Iva',
  },
];

const AccountEditActions = () => (
  <TopToolbar>
    {/*<Button*/}
    {/*	startIcon={<DirectionsCar />}*/}
    {/*	color="primary"*/}
    {/*	size="small"*/}
    {/*	onClick={() => console.log('Asd')}*/}
    {/*>*/}
    {/*	Promote to driver*/}
    {/*</Button>*/}
  </TopToolbar>
);

const EditAccountAside = ({ record: account, ...props }: any) => {
  const [driverProfile, setDriverProfile] = useState<
    GetAccountDriverQuery['driver_profile'][number] | null
  >(null);
  const [driverSettingsDialogOpen, setDriverSettingsDialogOpen] =
    useState(false);
  const [deactivateDriverDialogOpen, setDeactivateDriverDialogOpen] =
    useState(false);

  const { loading: isDriverLoading, refetch: fetchAccountDriver } =
    useGetAccountDriverQuery({
      skip: true,
      variables: {
        account_id: account?.id,
      },
      onCompleted: async (data) => {
        setDriverProfile(data.driver_profile[0]);
      },
    });

  const fetchDriver = async () => {
    const data = await fetchAccountDriver({
      account_id: account?.id,
    });

    setDriverProfile(data?.data?.driver_profile[0]);
  };

  useEffect(() => {
    if (account) {
      fetchDriver();
    }
  }, [account]);

  const handleClosePromoteDriverDialog = async () => {
    setDriverSettingsDialogOpen(false);
    fetchDriver();
  };

  return (
    <>
      <Box width={1 / 4} pl="1em" display="flex">
        <Card style={{ flex: 1 }}>
          <CardContent>
            <Typography variant="subtitle1">INFORMATION</Typography>
            <hr />
            {!account || isDriverLoading ? (
              <Typography>Loading...</Typography>
            ) : (
              <Box minWidth="200px">
                {driverProfile ? (
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <LocalTaxiOutlined color="primary" />
                      &nbsp;User is a driver{' '}
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setDriverSettingsDialogOpen(true)}
                      >
                        Edit driver
                      </Button>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setDeactivateDriverDialogOpen(true)}
                      >
                        Deactivate driver
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box mb="0.5em">
                      <Typography variant="body2">
                        User is NOT a driver
                      </Typography>
                    </Box>
                    <Button
                      color="primary"
                      size="small"
                      variant={'outlined'}
                      onClick={() => setDriverSettingsDialogOpen(true)}
                    >
                      Promote to driver
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
      <DeactivateDriverDialog
        open={deactivateDriverDialogOpen}
        closeCallback={() => {
          setDeactivateDriverDialogOpen(false);
          window.location.reload();
        }}
        accountId={account?.id as string}
      />
      <DriverSettingsDialog
        accountId={account?.id}
        driverProfile={driverProfile}
        open={driverSettingsDialogOpen}
        closeCallback={handleClosePromoteDriverDialog}
      />
    </>
  );
};

export const AccountEdit = (props: any) => {
  const [mutate] = useMutation();
  const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState(false);

  const save = useCallback(
    async (values) => {
      // Due to custom save mutation, we need to remove some stuff that tries to get pushed with values
      const data = { ...values };
      delete data.driver_profile;
      delete data.voucherify_customer_id;
      delete data.email;

      try {
        await mutate(
          {
            type: 'update',
            resource: 'account',
            payload: { id: props.id, data },
          },
          { returnPromise: true, mutationMode: 'pessimistic' }
        );
        await mutate(
          {
            type: 'update',
            resource: 'driver_profile',
            payload: {
              id: values.driver_profile.id,
              data: {
                bank_account_number: values.driver_profile.bank_account_number,
                bank_account_holder_name:
                  values.driver_profile.bank_account_holder_name,
                citizenship: values.driver_profile.citizenship,
                tax_regime: values.driver_profile.tax_regime,
                residence_permit: values.driver_profile.residence_permit,
                admin_status: values.driver_profile.admin_status,
                admin_suspension_date:
                  values.driver_profile.admin_suspension_date,
                admin_note: values.driver_profile.admin_note,
              },
            },
          },
          { returnPromise: true }
        );
      } catch (error: any) {
        console.log(error);
      }
    },
    [mutate]
  );

  return (
    <>
      <Edit
        aside={<EditAccountAside />}
        actions={<AccountEditActions />}
        mutationMode="pessimistic"
        {...props}
      >
        <FormWithRedirect
          save={save}
          {...props}
          render={({ record, form, ...formProps }) => {
            const [mutate] = useMutation();
            let accountFeedbackData: UseGetOneHookValue<any> | null = null;
            const [feedbackRecordId, setFeedbackRecordId] = useState<any>('');
            const [accountFeedbackMetadata, setAccountFeedbackMetadata] =
              useState({});
            const accountId = record?.id;

            if (accountId) {
              accountFeedbackData = useGetList(
                'account_feedback',
                { page: 1, perPage: 10 },
                { field: 'id', order: 'DESC' },
                { account_id: accountId }
              );
            }

            // Effect to initialize the fields
            useEffect(() => {
              const feedbackIds = Object.keys(accountFeedbackData?.data);
              if (feedbackIds.length) {
                const feedbackRecord =
                  accountFeedbackData?.data[feedbackIds[0]];
                setFeedbackRecordId(feedbackRecord.id);
                const object: { [key: string]: any } = {};

                Object.keys(accountFeedbackFields).forEach((propertyName) => {
                  object[propertyName] =
                    feedbackRecord.metadata[propertyName] || '';
                });

                setAccountFeedbackMetadata(object);
              }
            }, [accountFeedbackData?.data]);

            const updateFunction = useCallback(() => {
              mutate({
                type: 'update',
                resource: 'account_feedback',
                payload: {
                  id: feedbackRecordId,
                  data: {
                    metadata: accountFeedbackMetadata,
                  },
                },
              });
            }, [accountFeedbackMetadata, feedbackRecordId]);

            const saveFeedbackMetadata = () => {
              updateFunction();
            };

            return (
              <form>
                <Box display="flex" flexWrap="wrap">
                  <Box width={1 / 2}>
                    <Box p="1em">
                      <Box display="flex" alignItems="center">
                        <Labeled label="Email" record={record}>
                          <TextField
                            source="email"
                            label="Email"
                            record={record}
                          />
                        </Labeled>
                      </Box>
                      <Box mb={2}>
                        <Button
                          color="primary"
                          variant="outlined"
                          size={'small'}
                          onClick={() => setChangeEmailDialogOpen(true)}
                        >
                          Change email
                        </Button>
                      </Box>
                      <Box>
                        <TextInput
                          source="personal_data.first_name"
                          label="First Name"
                          style={{ width: '60%' }}
                        />
                      </Box>
                      <Box>
                        <TextInput
                          source="personal_data.last_name"
                          label="Last Name"
                          style={{ width: '60%' }}
                        />
                      </Box>
                      <Box>
                        <TextInput
                          source="personal_data.phone_number"
                          label="Phone number"
                          style={{ width: '60%' }}
                        />
                      </Box>
                      <Box>
                        <TextInput
                          source="personal_data.fiscal_code"
                          label="Fiscal code"
                          style={{ width: '60%' }}
                        />
                      </Box>
                      <Box>
                        <TextInput
                          source="personal_data.home_address"
                          label="Home address"
                          style={{ width: '60%' }}
                        />
                      </Box>
                      <Box>
                        <TextInput
                          source="personal_data.residence_address"
                          label="Residence address"
                          style={{ width: '60%' }}
                        />
                      </Box>
                      {record?.driver_profile && (
                        <Box>
                          <Box>
                            <TextInput
                              source="driver_profile.bank_account_number"
                              label="Bank account number"
                              style={{ width: '60%' }}
                            />
                          </Box>
                          <Box>
                            <TextInput
                              source="driver_profile.bank_account_holder_name"
                              label="Bank account holder"
                              style={{ width: '60%' }}
                            />
                          </Box>
                          <Box mb={2}>
                            <FormControl style={{ width: '60%' }}>
                              <InputLabel>Tax regime</InputLabel>
                              <Select
                                multiple={false}
                                defaultValue={
                                  record?.driver_profile?.tax_regime
                                }
                                value={form.getFieldState(
                                  'driver_profile.tax_regime'
                                )}
                                onChange={(e) => {
                                  form.change(
                                    'driver_profile.tax_regime',
                                    e.target.value
                                  );
                                }}
                                fullWidth
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                  <Box display="flex" flexWrap="wrap">
                                    <Box key={selected} p="0.15em">
                                      <Chip
                                        key={selected}
                                        label={
                                          selected.length > 0
                                            ? ADMIN_SUPPORTED_TAX_REGIMES.find(
                                                (t) => t.value === selected
                                              )?.label
                                            : ''
                                        }
                                      />
                                    </Box>
                                  </Box>
                                )}
                              >
                                {ADMIN_SUPPORTED_TAX_REGIMES.map((name) => (
                                  <MenuItem key={name.value} value={name.value}>
                                    {name.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box>
                            <TextInput
                              style={{ width: '60%' }}
                              source="driver_profile.citizenship"
                              label="Citizenship"
                            />
                          </Box>
                          {record?.driver_profile?.citizenship &&
                            record?.driver_profile?.citizenship.length > 0 &&
                            record?.driver_profile?.citizenship !==
                              'italian' && (
                              <Box mb={2}>
                                <FormControl style={{ width: '60%' }}>
                                  <InputLabel>Residence permit</InputLabel>
                                  <Select
                                    multiple={false}
                                    defaultValue={
                                      record?.driver_profile?.residence_permit
                                    }
                                    value={form.getFieldState(
                                      'driver_profile.residence_permit'
                                    )}
                                    onChange={(e) => {
                                      form.change(
                                        'driver_profile.residence_permit',
                                        e.target.value
                                      );
                                    }}
                                    fullWidth
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={(selected) => (
                                      <Box display="flex" flexWrap="wrap">
                                        <Box key={selected} p="0.15em">
                                          <Chip
                                            key={selected}
                                            label={
                                              selected.length > 0
                                                ? ADMIN_SUPPORTED_RESIDENCE_PERMITS.find(
                                                    (t) => t.value === selected
                                                  )?.label
                                                : ''
                                            }
                                          />
                                        </Box>
                                      </Box>
                                    )}
                                  >
                                    {ADMIN_SUPPORTED_RESIDENCE_PERMITS.map(
                                      (name) => (
                                        <MenuItem
                                          key={name.value}
                                          value={name.value}
                                        >
                                          {name.label}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Box>
                            )}
                          <Box mb={2}>
                            <FormControl style={{ width: '60%' }}>
                              <InputLabel>Admin status</InputLabel>
                              <Select
                                multiple={false}
                                defaultValue={
                                  record?.driver_profile?.admin_status
                                }
                                value={form.getFieldState(
                                  'driver_profile.admin_status'
                                )}
                                onChange={(e) => {
                                  form.change(
                                    'driver_profile.admin_status',
                                    e.target.value
                                  );
                                }}
                                fullWidth
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                  <Box display="flex" flexWrap="wrap">
                                    <Box key={selected} p="0.15em">
                                      <Chip
                                        key={selected}
                                        label={
                                          selected.length > 0
                                            ? ADMIN_SUPPORTED_STATUS.find(
                                                (t) => t.value === selected
                                              )?.label
                                            : ''
                                        }
                                      />
                                    </Box>
                                  </Box>
                                )}
                              >
                                {ADMIN_SUPPORTED_STATUS.map((name) => (
                                  <MenuItem key={name.value} value={name.value}>
                                    {name.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box>
                            <DateInput
                              style={{ width: '60%' }}
                              source="driver_profile.admin_suspension_date"
                              emptyText="n/a"
                              label="Suspension date"
                            />
                          </Box>
                          <Box>
                            <TextInput
                              source="driver_profile.admin_note"
                              multiline={true}
                              style={{ width: '60%' }}
                              label="Admin notes"
                            />
                          </Box>
                        </Box>
                      )}
                      <Box>
                        <NumberInput
                          source="discount_percent"
                          label="Discount percent"
                        />
                      </Box>
                      <Box>
                        <Typography variant="h6">User permissions</Typography>
                        <BooleanInput
                          source="permissions.marketing"
                          label="Marketing"
                        />
                        <BooleanInput source="permissions.toc" label="TOC" />
                        <BooleanInput
                          source="permissions.privacy_policy"
                          label="Privacy policy"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box width={1 / 2} pt={2}>
                    {record?.is_champion_patient && (
                      <Box>
                        <Labeled
                          label="This user is a champion"
                          record={record}
                        >
                          <FunctionField
                            render={() => (
                              <span style={{ fontSize: '28px' }}>🏆</span>
                            )}
                          />
                        </Labeled>
                      </Box>
                    )}
                    <Box>
                      <Labeled label="Voucherify customer ID" record={record}>
                        <TextField
                          source="voucherify_customer_id"
                          label="Voucherify customer ID"
                          record={record}
                          emptyText="n/a"
                        />
                      </Labeled>
                    </Box>
                    <Box>
                      <Labeled label="Stripe customer ID" record={record}>
                        <TextField
                          source="payment_metadata.stripe_customer_id"
                          label="Stripe customer ID"
                          record={record}
                          emptyText="n/a"
                        />
                      </Labeled>
                    </Box>
                    <Box>
                      <Labeled
                        label="Stripe connected account ID"
                        record={record}
                      >
                        <TextField
                          source="payment_metadata.stripe_connected_account_id"
                          label="Stripe connected account ID"
                          record={record}
                          emptyText="n/a"
                        />
                      </Labeled>
                    </Box>
                  </Box>
                  <Box width={1}>
                    <Box px="1em">
                      <Typography variant="h6">Metadata</Typography>
                    </Box>
                    {record && (
                      <AccountMetadata
                        accountId={record?.id}
                        onChange={(v: any) => setAccountFeedbackMetadata(v)}
                        t
                        initialState={accountFeedbackMetadata}
                      />
                    )}
                  </Box>
                  <Box width={1}>
                    <Box display="flex" width={1} p="1em">
                      <FirebaseUserDetails account={record} />
                    </Box>
                  </Box>
                  {record?.driver_profile ? (
                    <Box width={1}>
                      <Box p="1em">
                        <Typography variant="h6">
                          Driver onboarding flow
                        </Typography>
                      </Box>
                      <Box display="flex" p="1em">
                        <DriverOnboardingFlowData account={record} />
                      </Box>
                    </Box>
                  ) : null}
                </Box>
                <Toolbar>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <SaveButton
                      onSuccess={saveFeedbackMetadata}
                      redirect={false}
                      disabled={false}
                      saving={formProps.saving}
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect
                      }
                    />
                  </Box>
                </Toolbar>
                <AccountEmailChangeDialog
                  open={changeEmailDialogOpen}
                  closeCallback={() => {
                    setChangeEmailDialogOpen(false);
                    window.location.reload();
                  }}
                  accountId={record?.id}
                />
              </form>
            );
          }}
        />
      </Edit>
    </>
  );
};
