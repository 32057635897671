import * as React from 'react';
import { useState } from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  Filter,
  FunctionField,
  List,
  ResourceComponentProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { GLOBAL_EXPORTER_SETTINGS } from '@ugo/data';
import { MenuItem, Select } from '@material-ui/core';
import { omit } from 'ramda';
import { BooleanInput, NullableBooleanInput } from 'ra-ui-materialui';

const AFilter = (props: any) => {
  const { setFilters, filterValues } = useListContext();
  const [selectedRole, setSelectedRole] = useState('-1');

  const onRoleSelect = (e) => {
    const val = e.target.value;

    let filter;

    if (val === 'patient') {
      filter = { ...filterValues, is_patient: true, is_driver: false };
      setSelectedRole('patient');
    } else if (val === 'driver') {
      filter = { ...filterValues, is_driver: true, is_patient: false };
      setSelectedRole('driver');
    } else {
      filter = omit(['is_driver', 'is_patient'], { ...filterValues });
      setSelectedRole('-1');
    }

    setFilters(filter, filter);
  };

  return (
    <div>
      <Filter {...props}>
        <TextInput source="id@_ilike" label="ID" alwaysOn />
        <TextInput source="email@_ilike" label="Email" alwaysOn />
        <TextInput source="last_name@_ilike" label="Last name" alwaysOn />
        <TextInput
          source="voucherify_customer_id@_ilike"
          label="Voucherify ID"
        />
        <NullableBooleanInput
          source="is_champion_patient"
          label="Is champion patient"
        />
        <Select
          label="Role"
          value={selectedRole}
          variant="filled"
          onChange={onRoleSelect}
          alwaysOn
          style={{ width: '200px', height: '48px', marginBottom: '8px' }}
        >
          <MenuItem key="" value="-1">
            All roles
          </MenuItem>
          <MenuItem key="patient" value="patient">
            Patients
          </MenuItem>
          <MenuItem key="driver" value="driver">
            Drivers
          </MenuItem>
        </Select>
      </Filter>
    </div>
  );
};

export const AccountList = (props: ResourceComponentProps) => (
  <List
    bulkActionButtons={false}
    {...props}
    filters={<AFilter />}
    actions={<AccountActions />}
    sort={{
      field: 'created_at',
      order: 'DESC',
    }}
    filterDefaultValues={{
      'deleted_at@_is_null': true,
    }}
  >
    <Datagrid>
      <FunctionField
        render={(record: any) => record.is_champion_patient && '🏆'}
      />
      <TextField source="id" label="ID" />
      <TextField source="email" />
      <TextField
        source="personal_data.first_name"
        emptyText="n/a"
        label="First name"
      />
      <TextField
        source="personal_data.last_name"
        emptyText="n/a"
        label="Last name"
      />
      <TextField source="roles" emptyText="n/a" label="Roles" />
      <DateField source="created_at" />

      <FunctionField
        render={(record: any) => <Link to={`/account/${record.id}`}>VIEW</Link>}
      />
    </Datagrid>
  </List>
);

const AccountActions = (props: any) => {
  const { className, basePath, total, resource, currentSort, filters } = props;

  const { showFilter, displayedFilters, filterValues, exporter } =
    useListContext();

  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        exporter={exporter || undefined}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};
